.model-editor-container {
	.model-editor-controls {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		gap: 24px;
		margin-bottom: 24px;

		& > * {
			margin: 0;
		}
	}

	.model-editor-content {
		display: flex;
		justify-content: space-between;
		min-height: 300px;

		& > div {
			flex-grow: 0;
			flex-basis: 49%;
		}

		& > .model-schema {
			margin-left: 21px;
		}

		.json-editor-container {
			position: relative;

			h4 {
				margin: 0;
			}

			.alert-container {
				margin: 0 0 12px 0;
			}

			.json-editor-components {
				display: flex;
				flex-flow: column nowrap;
				position: relative;
				width: 100%;
				height: 100%;

				&.error {
					border: 5px solid red;
				}

				.json-inner {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;

					.fence {
						margin: 0 !important;
						height: 100%;
					}
				}

				.json-outer {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
				}

				textarea {
					box-sizing: border-box;
					height: 100%;
					width: 100%;
					resize: none;
					margin: 0;
					padding: 1.4rem;
					font-family: Menlo, Monaco, Consolas, 'Andale Mono', 'Ubuntu Mono', 'Courier New', monospace;
					font-size: 13px;
					line-height: 1.5;
					text-align: left;
					white-space: pre;
					word-spacing: normal;
					word-break: normal;
					word-wrap: normal;

					-moz-tab-size: 4;
					-o-tab-size: 4;
					tab-size: 4;

					-webkit-hyphens: none;
					-ms-hyphens: none;
					hyphens: none;

					background: transparent;
					color: transparent;
					caret-color: rgb(255, 255, 255);
				}
			}
		}
	}
}
