@import '../../../../theme/variables.scss';

.header-editor-container {
	.header-editor {
		.header-group {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 40px;

			.header-name {
				flex-basis: 250px;
				flex-grow: 0;
				min-width: 150px;

				.dx-textbox {
					&.disabled {
						background-color: var(--theme-core-control-textbox-background-color);
					}
				}
			}

			.header-value {
				flex-grow: 1;
				min-width: 150px;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;

				.header-value-prefix {
					margin: 18px 10px 0 0;
				}

				.dx-label {
					flex-grow: 1;
				}

				.remove-icon {
					flex-grow: 0;
					display: none;
					flex-flow: row nowrap;
					align-items: center;
					margin: 22px 0 6px 12px;
					padding: 0;
					border-radius: 3px;

					.dx-button {
						margin: 0;
						padding: 6px;
						line-height: 0;

						.icon {
							cursor: pointer;
							line-height: 0;
						}
					}
				}
			}

			&:hover {
				.remove-icon {
					display: flex;
				}
			}
		}

		.add-header-button {
			margin-left: 0;
		}
	}
}
