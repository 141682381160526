@mixin columns($count) {
	&.columns-#{$count} {
		grid-template-columns: repeat(#{$count}, minmax(0, 1fr));
	}
}

.card-grid {
	display: grid;
	gap: 20px;

	@include columns(1);
	@include columns(2);
	@include columns(3);
	@include columns(4);
	@include columns(5);
	@include columns(6);

	&.columns-responsive {
		grid-template-columns: repeat(1, minmax(0, 1fr));

		@media screen and (min-width: 1200px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@media screen and (min-width: 1600px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@media screen and (min-width: 2000px) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@media screen and (min-width: 2400px) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
	}
}
