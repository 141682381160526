.operation-filter-widget {
	.http-verb-filter {
		margin: 20px 0;
		display: flex;
		flex-wrap: wrap;

		.filter-checkbox {
			margin: 0 55px 0 0;
		}
	}
}
