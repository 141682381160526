.permissions-display {
	.permission-icon {
		line-height: 0;
		font-size: 12px;
		margin-left: 6px;
	}

	.permission-icon-large {
		line-height: 0;
		font-size: 18px;
		margin-left: 6px;
	}

	.permission-icon--x {
		color: red;
	}

	.permission-icon--check {
		color: green;
	}
}
