.changelog {

    &__submit {
        margin: 0 0 2px 2px;
    }

    &--invalid {
        margin-left: 1em;
        color: red;
    }

    &__label {
        font-weight: bold;
    }
}