@import '../../theme/variables.scss';

$containerWidthClosed: 220px;
$containerWidthOpen: 340px;

.account-switcher {
	position: relative;
	width: $containerWidthClosed;
	height: 49px;
	padding: 0px;
	margin: 0;
	flex-grow: 0;
	flex-shrink: 0;
	background: var($--theme-core-control-background-color);
	border: 1px solid var(--theme-core-control-border-color);
	color: var($--theme-core-control-text-color);
	border-radius: 2px;

	&.open {
		border-radius: 2px 2px 0 0;
		border-bottom-width: 0;
		width: $containerWidthOpen;
	}

	&.open-mini {
		width: $containerWidthClosed;
	}

	.clickable {
		cursor: pointer;

		& .account-card {
			cursor: pointer;
		}
	}

	.current-account-container {
		height: 100%;
		padding: 0 9px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		gap: 10px;
		align-items: center;

		.account-list {
			overflow: hidden;
		}

		.no-account-text {
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 20px;
		}

		.toggle-button {
			border: 0;
			background-color: transparent;
			padding: 0;
			margin: 0;

			.icon {
				font-size: 11px;
				line-height: 0;
				color: var($--theme-core-control-text-color);
			}
		}
	}

	.accounts-dropdown {
		background: var($--theme-core-control-background-color);
		border: 1px solid var(--theme-core-control-border-color);
		color: var($--theme-core-control-text-color);
		border-top-width: 0;
		box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 2px 2px;
		padding: 0;
		margin: 0;

		width: $containerWidthOpen;
		flex-grow: 0;
		flex-shrink: 0;
		position: absolute;
		right: -1px;

		.add-account-button {
			float: right;
			margin-bottom: 0;
		}
		.remove-account-button {
			float: left;
			margin-bottom: 0;
		}
		.dropdown-content-group {
			padding: 21px 15px;
			border-top: 1px solid var(--theme-core-control-border-color);

			.content-title {
				margin: 40px 0 12px 0;
				font-weight: 300;
				font-size: 16px;
				line-height: 20px;

				&:first-child {
					margin-top: 0;
				}
			}

			.toggle-row {
				display: flex;
				flex-flow: row wrap;
				gap: 40px;

				.dx-label {
					margin-top: 0;
				}
			}

			&.selected-account {
				.selected-account-overview {
					display: flex;
					flex-flow: row nowrap;
					gap: 9px;

					& > img {
						width: 70px;
						height: 70px;
						border-radius: 32px;
					}

					.account-info {
						display: flex;
						flex-flow: column nowrap;
						justify-content: space-around;
						align-items: flex-start;

						.account-name {
							font-size: 14px;
							line-height: 20px;
						}

						.account-org {
							font-size: 12px;
							line-height: 20px;
						}
					}
				}
			}

			.account-facts {
				margin: 18px 0 18px -6px;
				font-size: 12px;
				line-height: 16px;
				font-weight: 400;

				& div {
					margin: 7px 0;

					.label {
						display: inline-block;
						font-weight: 700;
						margin-right: 5px;
					}

					.copy-button {
						visibility: hidden;
						margin: 0 4px 0 0;
						padding: 1px;
						cursor: pointer;
						color: var($--theme-core-link-color);
						font-size: 12px;
						line-height: 0px;

						&:hover {
							color: var($--theme-core-link-hover-color);
						}
					}

					&:hover .copy-button {
						visibility: visible;
					}
				}
			}

			// Strip padding from the container and add it to the card so highlighting is full-size
			&.account-item {
				padding: 0;

				.account-card {
					padding: 12px;
					cursor: pointer;
				}

				&:hover {
					.account-card {
						background-color: var($--theme-core-control-hover-background-color);
					}
				}
			}
		}
	}

	.accounts-dropdown-mini {
		width: $containerWidthClosed;
	}
}
