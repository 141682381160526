@import '../../../theme/variables.scss';

.openapi-explorer-container {
	.openapi-explorer {
		.api-resource-accordion {
			margin-top: 0;
		}
	}

	// This is used multiple places in sub-components
	.required-text {
		color: var(--theme-alertblock-info-border-color);
		font-weight: bold;
	}
}
