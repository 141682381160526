@import '../../theme/variables.scss';

.feedback {
	margin: 90px auto 0 auto;
	border: 1px solid var($--theme-feedback-border-color);
	border-radius: 4px;
	max-width: 700px;
	background-color: var($--theme-feedback-background-color);

	&.complete {
		max-width: 800px;
	}

	.title {
		margin-top: 0;
	}

	&-closed {
		padding: 16px;
		text-align: center;
		display: flex;
		flex-flow: row nowrap;
		gap: 16px;
		align-items: center;
		justify-content: center;

		span {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 20px;
			color: var($--theme-feedback-text-color);
		}

		.thumbs-icon {
			color: var($--theme-core-link-color);
			cursor: pointer;
			font-size: 23px;
			line-height: 0;

			&:hover {
				color: var($--theme-core-link-hover-color);
			}
		}
	}

	&-open {
		padding: 33px;

		.metrics-container {
			margin: 40px 0;

			.metric-row {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: flex-start;
				gap: 15px;
				margin: 10px 0;

				.metric-question {
					display: inline-block;
					width: 280px;
				}

				.metric-icon {
					font-size: 20px;
					line-height: 20px;
					cursor: pointer;

					&.dim {
						opacity: 0.5;
					}

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	&-complete {
		padding: 30px 30px 0 30px;
		margin: 0;
		box-sizing: border-box;
		min-height: 290px;
		background-size: 290px;
		background-position: 20px bottom;
		background-repeat: no-repeat;

		.thanks-content {
			margin-left: 310px;
			padding: 0 0 30px 0;

			.thanks-heading {
				display: flex;
				flex-flow: row nowrap;
				gap: 10px;
				align-items: center;
				margin: 0 0 20px 0;

				.icon {
					font-size: 20px;
					line-height: 0;
					color: var($--theme-core-punch-color);
				}

				span {
					font-style: normal;
					font-weight: 300;
					font-size: 24px;
					line-height: 33px;
				}
			}

			p {
				margin: 20px 0;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					list-style-type: none;
					margin: 6px 0;
					padding: 0;
				}
			}
		}
	}

	@media (max-width: 1450px) {
		.feedback-complete {
			background-image: none !important;

			.thanks-content {
				margin-left: 0;
			}
		}
	}

	.validation-error {
		margin: 20px 0;
	}

	.input-row {
		display: flex;
		flex-flow: row;
		gap: 100px;

		.dx-label {
			flex-grow: 1;

			&.invalid {
				.dx-textbox,
				.dx-textarea {
					border-color: red;
				}
			}
		}

		.feedback-message textarea {
			height: 150px;
		}

		&.submit-row {
			flex-flow: row-reverse;
			gap: 20px;

			.dx-button {
				margin: 0;
			}
		}
	}
}
