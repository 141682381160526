@import '../../theme/variables.scss';

.breadcrumb {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: var($--theme-core-text-color);

	.crumb-container {
		display: inline-block;
	}

	.crumb {
		display: inline-block;
		margin: 0 3px;

		&.home-crumb {
			margin-left: 0;
		}

		& > a,
		& > a:link,
		& > a:visited {
			color: var($--theme-core-text-color);
		}

		& > a:hover {
			color: var($--theme-core-link-color);
		}
	}
}
