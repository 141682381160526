@import '../../theme/variables.scss';

.toast {
	position: relative;
	background-color: var($--theme-alertblock-toast-background-color);
	border: 1px solid var($--theme-alertblock-toast-border-color);
	color: var($--theme-alertblock-toast-text-color);
	box-shadow: 0px 4px 4px #00000044;
	border-radius: 4px;

	&.warning {
		color: var($--theme-alertblock-warning-text-color);
		background-color: var($--theme-alertblock-warning-background-color);
		border-color: var($--theme-alertblock-warning-border-color);

		.toast-body {
			.toast-icon {
				color: var($--theme-alertblock-warning-icon-color);
			}
		}

		.progress-bar {
			background-color: var($--theme-alertblock-warning-border-color);
		}
	}

	&.critical {
		color: var($--theme-alertblock-critical-text-color);
		background-color: var($--theme-alertblock-critical-background-color);
		border-color: var($--theme-alertblock-critical-border-color);

		.toast-body {
			.toast-icon {
				color: var($--theme-alertblock-critical-icon-color);
			}
		}

		.progress-bar {
			background-color: var($--theme-alertblock-critical-border-color);
		}
	}

	&.success {
		color: var($--theme-alertblock-success-text-color);
		background-color: var($--theme-alertblock-success-background-color);
		border-color: var($--theme-alertblock-success-border-color);

		.toast-body {
			.toast-icon {
				color: var($--theme-alertblock-success-icon-color);
			}
		}

		.progress-bar {
			background-color: var($--theme-alertblock-success-border-color);
		}
	}

	.toast-body {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start;
		gap: 8px;
		padding: 20px 10px 20px 10px;

		.toast-icon {
			color: var($--theme-alertblock-toast-icon-color);
			font-size: 16px;
			line-height: 19px;
			flex: none;
		}

		.toast-content {
			display: flex;
			flex-grow: 1;
			flex-flow: column nowrap;
			gap: 4px;
			margin-right: 20px;

			.content-title {
				font-weight: 700;
				font-size: 14px;
				line-height: 19px;
			}

			.content-text {
				font-size: 12px;
				line-height: 16px;
			}

			.content-link {
				font-size: 12px;
				line-height: 16px;
			}
		}

		.toast-x {
			flex: none;
			position: absolute;
			top: 12px;
			right: 12px;
			font-size: 11px;
			line-height: 11px;
			cursor: pointer;
		}
	}

	.toast-timeout-container {
		width: 100%;
		height: 3px;
		border-radius: 0 0 4px 4px;
		overflow: hidden;
		position: relative;

		.progress-bar {
			width: 100%;
			height: 100%;
			animation-name: progressBar;
			animation-timing-function: linear;
			animation-fill-mode: both;
		}

		@keyframes progressBar {
			0% {
				width: 100%;
			}
			100% {
				width: 0;
			}
		}
	}
}
