.model-container {
	display: flex;
	flex-direction: column;

	.subscribe-btn {
		margin: 7px 0px;
	}

	.parametereditor-input-field {
		margin: 5px 7px 5px 0px;
		padding-right: 5px;
	}
}
