.card-catalog {
	.subtype-container {
		margin: 20px 0;

		.subtype-filter-label {
			color: var(--theme-core-control-label-color);
			font-size: 12px;
		}

		.subtype-filter {
			display: flex;
			flex-flow: row wrap;

			.dx-button {
				margin: 0;
				border-radius: 0;
				padding: 0px 15px;

				&:first-child {
					border-radius: 4px 0 0 4px;
				}

				&:last-child {
					border-radius: 0 4px 4px 0;
				}
			}
		}
	}

	.card-list {
		display: grid;
		grid-auto-rows: 1fr;
		grid-auto-columns: 1fr;
		column-gap: 22px;
		row-gap: 22px;

		grid-template-columns: repeat(1, minmax(0, 1fr));

		@media screen and (min-width: 1200px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@media screen and (min-width: 1600px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@media screen and (min-width: 2000px) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@media screen and (min-width: 2400px) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}

		.card-container {
		}
	}

	.paginator {
		margin-top: 40px;
	}
}
