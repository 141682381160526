@import '../../../../theme/variables.scss';

.model-schema {
	.modelSchemaContainer {
		border-left: 1px solid var($--theme-core-layout-border-color);
		padding-left: 24px;

		h5 {
			text-transform: none;
			font-style: normal;
			font-weight: bold;
			margin-top: 0;
			color: #000000;
		}
	}

	.title-container {
		cursor: pointer;
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		gap: 6px;
		padding: 0 2px;
		position: relative;
		left: -21px;

		.chevron {
			font-size: 12px;
			line-height: 0;
			margin-top: 12px;
		}

		&.model-name-title {
			border-bottom: 1px solid var($--theme-core-layout-border-color);
			align-items: center;

			h5 {
				flex-grow: 1;
				margin: 0 0 2px 0;
				text-transform: none;
			}

			.chevron {
				margin-top: 0px;
			}
		}
	}

	.property-container {
		display: flex;
		margin: 6px 0;

		.property-name {
			width: 180px;
			flex-grow: 0;
			flex-shrink: 0;
			font-weight: bold;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.first-level {
		margin-left: 0 !important;
		border-left: 0 !important;
	}
}
