.operation-explorer {
	margin-top: 12px;

	& > .operation-section-header {
		margin-bottom: 0;
	}

	.operation-overview {
		// Accordion container style override
		margin-top: 0;

		.response-details {
			margin: 0;
		}

		.heading-icon {
			margin-right: 6px;
		}

		.tabbed-content {
			margin: 0;
		}
	}

	.scopes-permissions-container {
		display: flex;
		justify-content: space-between;

		& > div {
			width: 49%;
		}
	}

	.copy-button-container {
		margin: 12px 0;
		display: flex;
		flex-flow: row wrap;
		align-items: center;

		.dx-label {
			margin: 0 10px 12px 10px;
		}
	}

	.copy-link-button,
	.execute-button {
		.button-icon {
			margin-right: 6px;
		}
	}

	.copy-link-button {
		.tooltip-tip.top {
			// Adjust tooltip position
			top: -20px;
			bottom: unset;
		}
	}

	.parameter-list-container {
		width: 100%;
		display: flex;

		& > div {
			max-width: 50%;
			flex-basis: 50%;

			h4 {
				margin-top: 0;
			}

			.parameter-list {
				list-style-type: none;
				padding: 0 6px;
			}
		}
	}

	.request-controls-container {
		.fence {
			margin: 0;
		}

		.request-controls {
			margin: 16px 0;
			display: flex;
			justify-content: space-between;

			.account-card-container {
				.account-card {
					margin: 0;
				}
			}

			.execute-button-container {
				flex-grow: 1;
				align-self: stretch;
				margin: 6px 12px;

				button {
					width: 100%;
					height: 100%;
					margin: 0;
				}
			}
		}
	}

	.response-output {
		margin: 16px 0;
	}

	.response-data-container {
		display: flex;
		flex-direction: row;

		.response-data {
			border: 12px solid;
			border-radius: 0;
			transition: border-color 1s;

			&.ok {
				border-color: #3c8527;
			}

			&.notok {
				border-color: #ea0b0b;
			}

			&.stale {
				border-color: transparent;
				transition: border-color 3s;
			}
		}

		& > div {
			flex-basis: 50%;
			max-width: 50%;
		}
	}

	.custom-text-input {
		border: 3px solid red;
	}

	.model-body-container {
		margin: 3px 0;
	}

	.reading-mode-model {
		margin: 0 4px;
	}

	.reading-mode-json {
		.fence {
			margin: 0;
		}
	}

	.invocation-display {
		.fence {
			margin: 0;
		}
	}

	.execute-request-reading-mode {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		gap: 50px;
	}

	.error-codes {
		margin: 3px 6px;

		tr {
			border-bottom: 1px solid #606060;

			&.matched-error-code {
				// background-color: #5e5782;
				border: 3px solid #fbbe3b !important;
			}

			td {
				padding: 6px;

				code {
					background-color: transparent;
				}
			}
		}
	}

	.authorization {
		margin-top: -50px;
	}

	.response-status-ok {
		color: #24a357;
	}

	.response-status-failed {
		color: #cc3336;
	}

	.response-header-table {
		td {
			padding: 0 20px;
		}
	}

	.response-body-fence {
		.fence-body > pre > code {
			max-height: 80vh;
		}
	}
}
