@import '../../theme/variables.scss';

$size: 160px;

.loading-placeholder {
	position: relative;
	width: $size;
	height: $size;
	margin: 60px auto;

	.text {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 20px;
		color: var($--theme-loadingplaceholder-text-color);
		position: relative;
		top: 5px;
		left: 5px;
		opacity: 0.5;
	}

	div {
		position: absolute;
		border: 4px solid var($--theme-loadingplaceholder-wave-color);
		opacity: 1;
		border-radius: 50%;
		animation: loading-placeholder 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	div:nth-child(2) {
		animation-delay: -0.5s;
	}

	@keyframes loading-placeholder {
		0% {
			top: $size / 2;
			left: $size / 2;
			width: 0;
			height: 0;
			opacity: 1;
		}

		100% {
			top: 0px;
			left: 0px;
			width: $size;
			height: $size;
			opacity: 0;
		}
	}
}
