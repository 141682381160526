/****
 * The CSS in this file is originally based on the bootstrap library v5.1.1. It has been included
 * in this project to support legacy usage of the bootstrap column system since the site no longer 
 * uses bootstrap at a base.
 * https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.css
 */

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container-sm,
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container-md,
	.container-sm,
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1140px;
	}
}
@media (min-width: 1400px) {
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1320px;
	}
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.col {
	flex: 1 0 0%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.col-1 {
	flex: 0 0 auto;
	width: 8.33333333%;
}

.col-2 {
	flex: 0 0 auto;
	width: 16.66666667%;
}

.col-3 {
	flex: 0 0 auto;
	width: 25%;
}

.col-4 {
	flex: 0 0 auto;
	width: 33.33333333%;
}

.col-5 {
	flex: 0 0 auto;
	width: 41.66666667%;
}

.col-6 {
	flex: 0 0 auto;
	width: 50%;
}

.col-7 {
	flex: 0 0 auto;
	width: 58.33333333%;
}

.col-8 {
	flex: 0 0 auto;
	width: 66.66666667%;
}

.col-9 {
	flex: 0 0 auto;
	width: 75%;
}

.col-10 {
	flex: 0 0 auto;
	width: 83.33333333%;
}

.col-11 {
	flex: 0 0 auto;
	width: 91.66666667%;
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

@media (min-width: 576px) {
	.col-sm {
		flex: 1 0 0%;
	}

	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.col-md {
		flex: 1 0 0%;
	}

	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-md-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-md-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-md-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-md-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-md-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-md-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-md-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-md-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}

@media (min-width: 992px) {
	.col-lg {
		flex: 1 0 0%;
	}

	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.col-xl {
		flex: 1 0 0%;
	}

	.row-cols-xl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-xl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-xl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-xl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-xl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-xl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-xl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}
