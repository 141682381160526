.screenshare-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 700px;
	margin: 0 auto 0 0;
	padding: 0 80px 200px 0px;

	h3 {
		margin: 10px 0 !important;
	}

	& > div {
		margin-top: 20px;
	}

	.alert {
		text-align: center;
		margin-bottom: 5px;
	}

	.url-container {
		text-align: left;
	}

	.screenShare-accordion {
		margin-bottom: 20px;
	}
}

.warning-container {
	display: flex;
	flex-flow: column;
	width: 100%;
	justify-content: center;
	text-align: center;
	height: 200px;

	.icon {
		font-size: 48px;
		margin-top: 20px;
		color: #aeaeae;
	}

	span {
		color: #8b8b8b;
		font-size: 30px;
		font-weight: 300;
		margin-top: 20px;
		text-align: center;
	}
}
