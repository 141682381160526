@import '../../../../theme/variables.scss';

.topics-tile {
	margin: 6px 3px;
	border-left: 8px solid transparent;
	// Can't use border-radius here because the gradient for -all doesn't respect it
	// border-radius: 4px 0 0 4px;

	.tile-container {
		border-width: 1px 1px 1px 0;
		border-style: solid;
		border-color: var($--theme-core-layout-border-color);
		border-radius: 0 4px 4px 0;
		padding: 10px 9px;

		.tile-info-wrapper {
			display: flex;
			justify-content: space-between;

			.topic {
				flex-grow: 1;
				margin: 0;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				cursor: pointer;

				& > div {
					width: 100%;
				}

				.tile-text {
					line-height: 22px;
					flex: 1 0;
					min-width: 50%;
					max-width: 500px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.topic-id {
					font-weight: bold;
				}

				&.expanded .tile-text {
					overflow: visible;
					text-overflow: inherit;
					white-space: normal;
					min-width: 100%;
				}
			}
		}

		& > .content {
			margin-top: 12px;
		}
	}

	&.transport-Websocket {
		border-left-width: 12px;
		border-left-color: #c78800;
	}

	&.transport-EventBridge {
		border-left-width: 12px;
		border-left-color: #8452cf;
	}

	&.transport-ProcessAutomation {
		border-left-width: 12px;
		border-left-color: #00ae9e;
	}

	&.transport-EventBridgeandProcessAutomation,
	&.transport-ProcessAutomationandEventBridge {
		border-left-width: 12px;
		border-left-style: solid;
		border-image: linear-gradient(to left, rgba(0, 174, 158, 1) 50%, rgba(132, 82, 207, 1) 50%) 1 100%;
	}

	&.transport-EventBridgeandWebsocket,
	&.transport-WebsocketandEventBridge {
		border-left-width: 12px;
		border-left-style: solid;
		border-image: linear-gradient(to left, rgba(132, 82, 207, 1) 50%, rgba(199, 136, 0, 1) 50%) 1 100%;
	}

	//Acts as a safegaurd incase of inconsistent transport value order
	&.transport-All,
	&.WebsocketandEventBridgeandProcessAutomation,
	&.WebsocketandProcessAutomationandEventBridge,
	&.ProcessAutomationandEventBridgeandWebsocket,
	&.ProcessAutomationandWebsocketandEventBridge,
	&.EventBridgeandWebsocketandProcessAutomation,
	&.EventBridgeandProcessAutomationandWebsocket {
		border-left-width: 12px;
		border-left-style: solid;
		border-image: linear-gradient(
				to left,
				rgba(199, 136, 0, 1) 33.33%,
				rgba(132, 82, 207, 1) 33.33% 66.66%,
				rgba(0, 174, 158, 1) 66.66% 99.99%
			)
			1 100%;
	}
}
