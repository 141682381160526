@import '../../../../theme/variables.scss';

.response-container {
	margin: 0;
	padding: 3px 3px 3px 0;

	.response-header {
		cursor: pointer;
		padding: 0 0 3px 0;
		border-bottom: 1px solid var($--theme-core-layout-border-color);

		.collapse-arrow {
			margin-right: 6px;
		}
	}

	.response-content {
		margin: 3px;
	}
}
