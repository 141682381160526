.field-validation-container {
	&.invalid {
		.dx-textbox,
		.dx-textarea {
			border-color: red;
		}
	}
}

ImageUploader {
	&.invalid {
		border-color: red;
	}
}
