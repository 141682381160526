@import '../../theme/variables.scss';

.blog-author-bio {
	border-color: var($--theme-core-layout-border-color);
	border-width: 1px 0;
	border-style: solid;
	margin: 80px 0;
	padding: 15px;
	min-height: 212px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 30px;

	@media screen and (max-width: 1300px) {
		flex-flow: column nowrap;
		.author-content {
			text-align: center;
		}
	}

	.author-image {
		height: 180px;
		width: 180px;
		border-radius: 50%;
		float: left;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.author-content {
		flex-grow: 1;

		.author-name {
			font-size: 26px;
			font-weight: bold;
		}

		.author-subtitle {
			font-size: 18px;
		}

		.author-bio-text {
			text-align: justify;
			margin-bottom: 0;
		}
	}
}
