@import '../../../../../theme/variables.scss';

.array-container {
	margin: 6px 6px 0px 12px;
	border-left: 1px solid #69fd69;
	padding-left: 6px;

	.add-button {
		width: 280px;
		margin: 10px 0;

		.add-icon {
			line-height: 0;
			margin-left: 4px;
		}
	}

	.array-element-container {
		display: flex;
		flex-flow: row nowrap;
		gap: 10px;
		align-items: stretch;
		margin: 20px 0;

		.array-remove-icon {
			flex-grow: 0;
			display: none;
			flex-flow: row nowrap;
			align-items: center;
			margin: 0 0 0 3px;
			padding: 3px;
			border-radius: 0 3px 3px 0;
			height: 100%;

			.icon {
				cursor: pointer;
				line-height: 0;
				font-size: 10px;
			}
		}

		& > *:first-child {
			flex-grow: 1;
			margin: 0;
		}

		&:hover {
			& > .tooltip-container > .array-remove-icon {
				display: flex;
				background-color: var($--theme-dxbutton-primary-background-color);
				color: var($--theme-dxbutton-primary-text-color);
			}
		}
	}
}
