/* Custom properties */
:root {
	--tooltip-text-color: white;
	--tooltip-background-color: black;
	--tooltip-margin: 10px;
	--tooltip-arrow-size: 6px;
}

/* Wrapping */
.tooltip-container {
	display: inline-block;
	position: relative;
	line-height: 0;

	/* Absolute positioning */
	.tooltip-tip {
		position: absolute;
		border-radius: 4px;
		left: 50%;
		transform: translateX(-50%);
		padding: 6px;
		color: var(--tooltip-text-color);
		background: var(--tooltip-background-color);
		font-size: 14px;
		line-height: 1;
		z-index: 10000;
		max-width: 300px;
		width: max-content;
		opacity: 0;
		visibility: hidden;
		transition: visibility 1.1s ease-out, opacity 1s ease-out;

		&.visible {
			opacity: 1;
			visibility: visible;
			transition: visibility 0s, opacity 0.1s ease-in;
		}

		/* CSS border triangles */
		&::before {
			content: ' ';
			left: 50%;
			border: solid transparent;
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-width: var(--tooltip-arrow-size);
			margin-left: calc(var(--tooltip-arrow-size) * -1);
		}

		/* Absolute positioning */
		&.top {
			// top: calc(var(--tooltip-margin) * -1);
			bottom: 130%;

			/* CSS border triangles */
			&::before {
				top: 100%;
				border-top-color: var(--tooltip-background-color);
			}
		}

		/* Absolute positioning */
		&.right {
			left: calc(100% + var(--tooltip-margin));
			top: 50%;
			transform: translateX(0) translateY(-50%);

			/* CSS border triangles */
			&::before {
				left: calc(var(--tooltip-arrow-size) * -1);
				top: 50%;
				transform: translateX(0) translateY(-50%);
				border-right-color: var(--tooltip-background-color);
			}
		}

		/* Absolute positioning */
		&.bottom {
			// bottom: calc(var(--tooltip-margin) * -1);
			top: 110%;

			/* CSS border triangles */
			&::before {
				bottom: 100%;
				border-bottom-color: var(--tooltip-background-color);
			}
		}

		/* Absolute positioning */
		&.left {
			left: auto;
			right: calc(100% + var(--tooltip-margin));
			top: 50%;
			transform: translateX(0) translateY(-50%);

			/* CSS border triangles */
			&::before {
				left: auto;
				right: calc(var(--tooltip-arrow-size) * -2);
				top: 50%;
				transform: translateX(0) translateY(-50%);
				border-left-color: var(--tooltip-background-color);
			}
		}
	}
}
