@import '../../theme/variables.scss';

.tag {
	font-size: 12px;
	line-height: 14px;
	color: var($--theme-core-tag-background-color);
	border: 1px solid var($--theme-core-tag-background-color);
	border-radius: 8px;
	padding: 2px 10px;
}
