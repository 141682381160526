@import '../../../theme/variables.scss';

.dx-card-icon {
	.dx-card-headline {
		min-height: 240px;
		margin: -18px;
		margin-bottom: 12px;

		img {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 240px;

			&.placeholder-image {
				opacity: 0.15;
			}
		}
	}

	.dx-card-title-container {
		margin-bottom: 8px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		// Eliminates whitespace in anchor link around image
		line-height: 0;

		.dx-card-title {
			float: left;

			span {
				display: block;
			}

			.dx-card-title-text {
				font-size: 16px;
				line-height: 20px;

				// Clamp to 2 lines
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.dx-card-subtitle-text {
				font-size: 14px;
				font-style: italic;
				color: var($--theme-core-tag-background-color);

				// Clamp to 1 line
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
			}
		}

		&.icon {
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			color: var($--theme-card-text-color);

			.icon {
				font-size: 24px;
				color: var($--theme-card-icon-color);
				margin-right: 8px;
				flex-shrink: 0;
			}
		}

		&.large-icon {
			flex-direction: column;
			align-items: flex-start;

			.dx-card-title-text {
				display: inline-block;
				margin: 6px 0;
				font-weight: 300;
				font-size: 24px;
				line-height: 28px;
				color: var($--theme-card-text-color);
			}

			.icon {
				display: block;
				font-size: 36px;
				color: var($--theme-card-icon-color);
				margin: 20px 0 17px 0;
				flex-shrink: 0;
			}
		}
	}

	.dx-card-author {
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		.author-image > img {
			width: 35px;
			height: 35px;
			border-radius: 35px;
			flex-shrink: 0;
			margin-right: 8px;
		}
	}

	.dx-card-body {
		max-height: 200px;
		overflow: hidden;
		color: var($--theme-card-text-color);
		font-size: 14px;
		line-height: 20px;

		&-text {
			// Truncate body
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 8;
			overflow: hidden;
		}

		&.large-icon {
			.dx-card-body-text {
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				color: var($--theme-card-text-color);
			}
		}
	}

	.dx-card-gutter {
		height: 37px;

		&-content {
			position: absolute;
			bottom: 0;
			width: 100%;
			margin-left: -18px;
			padding: 18px;
		}

		img {
			width: 25px;
			height: 25px;
		}

		&-left {
			float: left;
			img {
				margin-right: 6px;
			}
		}

		&-right {
			text-align: right;
			float: right;
			img {
				margin-left: 6px;
			}
		}
	}
}
