@import '../../../theme/variables.scss';

.dx-card-plain {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: stretch;
	gap: 8px;
	height: 100%;

	.title {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
	}

	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: var($--theme-core-tag-background-color);
	}

	.text {
		flex-grow: 1;
		flex-shrink: 1;

		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		overflow: hidden;
	}

	.author-info {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;

		img {
			width: 28px;
			height: 28px;
			border-radius: 14px;
		}
	}

	.tags {
		display: flex;
		flex-flow: row wrap;
		gap: 8px;
		align-items: center;
		justify-content: space-around;
	}
}
