@import '../../theme/variables.scss';

.add-account-selector {
	.region-selection {
		text-align: center;

		.title {
			display: inline-block;
			margin-bottom: 6px;
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 20px;
			color: var($--theme-core-text-color);
		}

		.region-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			.dx-button {
				flex: none;
				width: 30%;
				margin: 4px 0;
			}
		}
	}
}
