@import '../../../../theme/variables.scss';

.event-card-container {
	border: solid 1px var($--theme-core-layout-border-color);
	background-color: var($--theme-core-background-color);
	border-radius: 5px;
	margin: 10px 0px;
	padding: 15px 15px 10px 10px;
	min-height: 50px;

	.event-details-container {
		display: flex;
		flex-direction: column;

		.event-details {
			display: grid;
			grid-template-columns: auto 1fr auto 1fr 15px;
			column-gap: 10px;
			row-gap: 5px;
			min-height: 50px;
			margin: 0px 7px 7px 7px;
			padding: 0px 5px 2px 5px;
			justify-content: space-between;

			& > span {
				margin: 0;
				padding: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.variable-event-details-label {
				min-width: 100px;
			}

			.event-details-label {
				font-weight: bold;
			}
		}

		.code-fence {
			margin: 5px 0px 0px 10px;
			max-height: 150px;
			overflow: scroll;
		}
	}
}

.pin {
	color: var($--theme-core-text-color);
	cursor: pointer;
	margin: 3px;
}
