.webchat-two-container {
	.chat-configuration {
		display: grid;
		grid-template-columns: auto 80px auto;

		.new-deployment-icon-container {
			margin: 40px 40px 40px 10px;
			.new-deployment-icon {
				font-size: 30px;
				color: #75757a;
				cursor: pointer;
			}
		}
	}

	#webchat2-iframe {
		margin-top: 20px;
		width: 100%;
		height: 460px;
	}

	.hide-iframe {
		display: none;
	}

	.start-chat-button-container {
		margin: 0 20px 0px 0;

		.start-chat-button {
			height: 100%;
			width: 100%;
		}
	}

	.resetConfigButton {
		margin-left: 0;
	}
}

.no-account-warning {
	display: flex;
	flex-flow: column;
	width: 100%;
	justify-content: center;
	text-align: center;
	height: 200px;

	.icon {
		font-size: 48px;
		margin-top: 20px;
		color: #aeaeae;
	}

	span {
		color: #8b8b8b;
		font-size: 30px;
		font-weight: 300;
		margin-top: 20px;
		text-align: center;
	}
}
