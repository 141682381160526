.topics-container {
	.transport-filter {
		display: flex;
		flex-direction: row;
		gap: 15px;
		margin: 15px 0;
		align-items: flex-start;

		.dx-checkbox {
			margin: 0;
		}

		.filter-EventBridge span {
			border-bottom: 3px solid #8452cf;
		}

		.filter-Websocket span {
			border-bottom: 3px solid #c78800;
		}

		.filter-ProcessAutomation span {
			border-bottom: 3px solid #00AE9E;
		}
	}

	.headings-container {
		display: grid;
		grid-template-columns: auto auto;

		h4 {
			margin: 10px 0px;
			&.description {
				margin-left: 30px;
			}
		}
	}
}
