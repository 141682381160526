@import '../../../theme/variables.scss';

.alert-container {
	margin: 40px 0;
	padding: 0;

	// Strip top margin when a sibling of certain elements
	.toc-link + & {
		margin-top: 0;
	}

	.alert {
		display: flex;
		flex-flow: row nowrap;
		gap: 20px;
		background-color: var($--theme-alertblock-default-background-color);
		border: 1px solid var($--theme-alertblock-default-border-color);
		color: var($--theme-alertblock-default-text-color);
		border-radius: 4px;
		padding: 15px 20px;
		font-size: 14px;
		line-height: 14px;

		.clickable {
			cursor: pointer;
		}

		.info-icon {
			display: inline-block;
			font-size: 16px;
			line-height: 0;
			margin-top: 2px;
			color: var($--theme-alertblock-default-icon-color);
		}

		.alert-content {
			flex-grow: 1;
			font-size: 14px;
			line-height: 20px;
			margin: 0;

			.alert-title {
				font-size: 14px;
				line-height: 20px;
				font-weight: bold;
				margin-bottom: 6px;

				&.collapsed {
					margin-bottom: 0;
				}
			}
		}

		p:last-of-type {
			margin-bottom: 0;
		}

		&.alert-info {
			color: var($--theme-alertblock-info-text-color);
			background-color: var($--theme-alertblock-info-background-color);
			border-color: var($--theme-alertblock-info-border-color);

			.info-icon {
				color: var($--theme-alertblock-info-icon-color);
			}
		}

		&.alert-warning {
			color: var($--theme-alertblock-warning-text-color);
			background-color: var($--theme-alertblock-warning-background-color);
			border-color: var($--theme-alertblock-warning-border-color);

			.info-icon {
				color: var($--theme-alertblock-warning-icon-color);
			}
		}

		&.alert-critical {
			color: var($--theme-alertblock-critical-text-color);
			background-color: var($--theme-alertblock-critical-background-color);
			border-color: var($--theme-alertblock-critical-border-color);

			.info-icon {
				color: var($--theme-alertblock-critical-icon-color);
			}
		}

		&.alert-success {
			color: var($--theme-alertblock-success-text-color);
			background-color: var($--theme-alertblock-success-background-color);
			border-color: var($--theme-alertblock-success-border-color);

			.info-icon {
				color: var($--theme-alertblock-success-icon-color);
			}
		}

		&.alert-toast {
			color: var($--theme-alertblock-toast-text-color);
			background-color: var($--theme-alertblock-toast-background-color);
			border-color: var($--theme-alertblock-toast-border-color);

			.info-icon {
				color: var($--theme-alertblock-toast-icon-color);
			}
		}
	}
}
