.swagger-diff {
	.options-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 60px;

		.dx-label {
			flex-grow: 1;
		}
	}

	.diff-button {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.accordion-content > div > *:first-child {
		margin-top: 0;
	}
}
