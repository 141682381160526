@import '../../theme/variables.scss';

.landing-layout {
	height: 100vh;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	gap: 0;
	background-color: var($--theme-core-background-color);
	color: var($--theme-core-text-color);

	.layout-body {
		flex-grow: 1;
		overflow: auto;

		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
		gap: 0;
		padding: 0 40px;

		.hero {
			background-repeat: no-repeat;
			background-color: #23395d;
			color: white;
			border-bottom: 5px solid #ff4f1f;
			border-radius: 0 0 4px 4px;
			height: 353px;
			flex-grow: 0;
			flex-shrink: 0;
			padding: 0;
			margin-bottom: 34px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			gap: 100px;

			.yeti-wave {
				flex-grow: 0;
				align-self: flex-end;
			}

			@media screen and (max-width: 1000px) {
				.yeti-wave {
					display: none;
				}
			}

			.hero-contents {
				max-width: 500px;

				.hero-title {
					font-weight: 300;
					font-size: 48px;
					line-height: 56px;
					margin-bottom: 20px;
				}

				.hero-text {
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 20px;
				}
			}
		}

		@media screen and (max-width: 1000px) {
			.card-grid {
				grid-template-columns: 50% 50%;
			}
		}

		@media screen and (max-width: 650px) {
			.card-grid {
				grid-template-columns: 100%;
			}
		}

		.popular-topics-title {
			text-align: center;
		}

		.layout-footer {
			margin-left: -40px;
			margin-right: -40px;
		}
	}
}
