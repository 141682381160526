@import '../../theme/variables.scss';

.account-card {
	margin: 0;
	padding: 0;
	display: flex;
	flex-flow: row nowrap;
	gap: 6px;
	justify-content: space-between;
	align-items: stretch;
	cursor: default;
	background: var($--theme-core-control-background-color);
	color: var($--theme-core-control-text-color);

	.col-region {
		flex: none;
		// margin: -6px 0 -6px -6px;
		margin: 0;
		width: 12px;
		text-align: center;
		padding-top: 1px;

		& > span {
			transform: translateX(-50%) translateY(-50%) rotate(-90deg);
			display: block;
			position: relative;
			bottom: -50%;
			left: 50%;
			font-size: 12px;
			line-height: 12px;
			width: 40px;
			height: 14px;
		}
	}

	.col-image {
		flex: none;

		& > img {
			height: 40px;
			width: 40px;
			border-radius: 40px;
			float: left;
		}
	}

	.col-info {
		flex-grow: 1;
		min-width: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		padding: 0;
		font-size: 12px;
		line-height: 16px;

		& > span {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&:hover .col-remove-button {
		display: flex;
	}

	.col-remove-button {
		flex: none;
		font-size: 11px;
		line-height: 0;
		display: none;
		padding: 06px;

		.remove-button {
			align-self: center;
			padding: 3px;
			color: #2f7bb1;
			cursor: pointer;

			&:hover {
				color: #cc3336;
			}
		}
	}
}
