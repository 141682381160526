@import '../../theme/variables.scss';

.settings-consent {
	position: fixed;
	bottom: 0;
	left: 0;
	// width: 90%;
	margin: 0 10%;
	padding: 40px;
	background-color: var($--theme-privacybanner-background-color);
	border: 1px solid var($--theme-privacybanner-border-color);
	border-radius: 4px;
	z-index: 1000;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
	color: var($--theme-core-text-color);

	.settings-consent-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		gap: 120px;

		@media (max-width: 1000px) {
			& {
				flex-flow: column nowrap;
				align-items: flex-start;
				gap: 30px;

				.message-text {
					border: 0 !important;
					padding: 0 32px !important;
				}

				.right-column {
					margin: 10px 32px !important;
					align-self: unset !important;
				}

				.dx-button {
					margin: 10px 32px !important;
				}
			}
		}

		.left-column {
			flex-grow: 1;
		}

		.right-column {
			flex-grow: 0;
			flex-shrink: 0;
			margin: 80px 0 0 0;
			align-self: center;

			.privacy-settings {
				margin-bottom: 12px;
			}

			& > :last-child {
				margin-bottom: 0;
			}
		}

		.settings-consent-title {
			margin: 0 0 40px 0;
			font-style: normal;
			font-weight: 300;
			font-size: 24px;
			line-height: 33px;

			.icon {
				font-size: 18px;
				margin-right: 12px;
				line-height: 0;
				color: var($--theme-core-punch-color);
			}
		}

		.message-text {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			margin: 0;
			padding: 0 80px 0 32px;
			border-right: 1px solid var($--theme-privacybanner-border-color);
		}

		.dx-button {
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0;
		}
	}

	.close-button {
		position: absolute;
		top: 18px;
		right: 18px;
		border: 0;
		background-color: transparent;
		cursor: pointer;
		font-size: 11px;
		line-height: 0;
		margin: 0;
		padding: 2px;
		color: var($--theme-core-text-color);

		&:hover {
			color: var($--theme-core-link-color);
		}
	}
}
