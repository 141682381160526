@import '../../theme/variables.scss';

.paginator {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	column-gap: 5px;
	margin: 10px 0 40px 0;

	// Chevron buttons
	.page-button {
		cursor: pointer;
		line-height: 0;
		color: var($--theme-core-link-color);

		&:hover {
			color: var($--theme-core-link-hover-color);
		}
	}

	button {
		border: 0;
		border-radius: 2px;
		font-weight: 300;
		font-size: 12px;
		line-height: 14px;
		background-color: transparent;
		color: var($--theme-core-text-color);
		padding: 2px 5px;
		margin: 0;
		cursor: pointer;

		&.selected {
			background-color: var($--theme-core-tag-background-color);
			color: var($--theme-core-tag-text-color);
			cursor: default;
		}
	}

	.results-text {
		display: block;
		margin-left: 30px;
	}

	.page-sizes {
		margin: 0;
	}
}
