.toaster {
	position: absolute;
	top: 100%;
	right: 0;
	width: 465px;
	display: grid;
	columns: 1;
	gap: 20px;
	padding: 20px 60px 0 0;
	z-index: 90;
}
