@import '../../../theme/variables.scss';

$get: #52cef8;
$post: #7d2;
$patch: #ff1dce;
$put: #ffda17;
$delete: red;
$head: #ff8f14;

$borderSize: 10px;

@mixin operationColorRule($verb, $color) {
	&.operation-#{$verb} {
		border-left: $borderSize solid $color;

		.verb {
			color: darken($color: $color, $amount: 20);
		}
	}
}

.openapi-operation-tile {
	margin: 6px 3px;

	.operation-container {
		border: 0;
		padding: 10px 9px;
		border-top: 1px solid var($--theme-core-layout-border-color);
		border-right: 1px solid var($--theme-core-layout-border-color);
		border-bottom: 1px solid var($--theme-core-layout-border-color);
		border-radius: 0 4px 4px 0;

		@include operationColorRule('get', $get);
		@include operationColorRule('post', $post);
		@include operationColorRule('patch', $patch);
		@include operationColorRule('put', $put);
		@include operationColorRule('delete', $delete);
		@include operationColorRule('head', $head);

		.operation {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			cursor: pointer;

			&.force-open {
				cursor: auto;
			}

			.verb,
			.path,
			.description {
				line-height: 22px;
			}

			.verb {
				flex: none;
				width: 65px;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 17px;
			}

			.path {
				flex: 0 0;
				font-weight: bold;
				font-size: 17px;
				padding-right: 8px;
				// Firefox needs this to not split the line and wrap on slashes
				white-space: nowrap;

				// targets elements with both classes to increase specificity and avoid important tag
				&.no-right-pad {
					padding-right: 0;
				}
			}

			.resource-tag {
				padding-right: 10px;

				.tag {
					margin-left: 12px;
					font-size: 16px;
					line-height: 18px;
					padding: 2px 12px;

					&.tag-preview {
						color: var($--theme-alertblock-info-text-color);
						background-color: var($--theme-alertblock-info-background-color);
						border-color: var($--theme-alertblock-info-border-color);
					}

					&.tag-unstable {
						color: var($--theme-alertblock-warning-text-color);
						background-color: var($--theme-alertblock-warning-background-color);
						border-color: var($--theme-alertblock-warning-border-color);
					}

					&.tag-internal {
						color: var($--theme-alertblock-critical-text-color);
						background-color: var($--theme-alertblock-critical-background-color);
						border-color: var($--theme-alertblock-critical-border-color);
					}
				}

				// targets elements with both classes to increase specificity and avoid important tag
				&.no-right-pad {
					padding-right: 0;
				}
			}

			.description {
				flex: 1 0;
				min-width: 400px !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			&.expanded .description {
				overflow: visible;
				text-overflow: inherit;
				white-space: normal;
				min-width: 100% !important;
			}

			.result {
				// min-width: 500px;
			}

			.bookmark-icon-container {
				margin: -1px 6px 0 0;

				.bookmark-icon-active {
					line-height: 0;
					color: rgb(255, 208, 0);
				}
			}
		}

		.content {
			margin-top: 12px;
		}
	}
}
