.quick-hit {
	margin: 60px 0 40px 0;

	@media only screen and (max-width: 1100px) {
		margin-left: 0;
		margin-right: 0;
	}

	.alert-container {
		margin-top: 10px;
	}

	.tabbed-content {
		.tab-content {
			padding-top: 0;

			// Remove margins from code fence because it's not standalone content here
			.fence {
				margin: 0;
			}
		}
	}

	.file-header {
		padding: 6px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 20px;

		.file-info {
			display: flex;
			flex-flow: column nowrap;
		}

		.github-link {
			font-size: 35px;
			line-height: 0;
		}
	}
}
