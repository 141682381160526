@import '../../theme/variables.scss';

.star-icon-container {
	.star-icon {
		cursor: pointer;

		&:hover {
			color: var($--theme-core-star-color);
		}

		&-active {
			line-height: 0;
			color: var($--theme-core-star-color);
		}
	}
}
