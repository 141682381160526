.video-catalog-container {
	border-bottom: 1px solid #e4e9f0;

	.video-category {
		.category-title {
			cursor: pointer;
			display: block;
			font-size: 18px;
			line-height: 20px;
			font-weight: bold;
			width: 100%;
			border-top: 1px solid #e4e9f0;
			padding: 10px 16px;

			.chevron {
				float: right;
			}
		}

		& > p {
			margin-left: 16px;
			margin-right: 16px;
		}
	}

	.video-catalog {
		margin: 16px;
		display: grid;
		grid-auto-rows: 1fr;
		grid-auto-columns: 1fr;
		column-gap: 22px;
		row-gap: 22px;

		grid-template-columns: repeat(1, minmax(0, 1fr));

		@media screen and (min-width: 1200px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@media screen and (min-width: 1600px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@media screen and (min-width: 2000px) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@media screen and (min-width: 2400px) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
	}
}

.ReactModalPortal {
	& > div {
		z-index: 100;
	}

	.video-modal {
		background-color: #000000e0;
		width: 100%;
		height: 100%;

		.video-container {
			display: block;
			margin: 0px auto;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 70px;

			.video-title {
				color: white;
				font-size: 24px;
				margin-bottom: 16px;
				text-align: center;
				display: block;
			}

			& > iframe {
				width: 100%;
				height: 94%;
			}
		}

		.close-modal {
			font-size: 40px;
			line-height: 40px;
			color: white;
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
		}
	}
}
