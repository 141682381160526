.chatdataform {
	margin-bottom: 0;

	.inputFields-container {
		margin: 0;
		display: flex;
		justify-content: space-between;

		& > div {
			width: 50%;
			margin: 0 10px;
		}

		.main-attributes-container {
			display: flex;
			flex-direction: column;
		}

		.custom-data-container {
			display: flex;
			flex-direction: column;

			.custom-fields-row {
				display: flex;
				justify-content: space-between;

				& > label {
					width: 100%;
				}

				.field-name {
					margin-right: 15px;
				}
			}

			.custom-attributes {
				display: flex;
				margin: 0;
				justify-content: space-between;

				.attribute-fields {
					display: flex;
					justify-content: flex-start;
					flex-grow: 10;

					.attribute-field {
						margin-right: 32px;
						width: 100%;
					}
				}

				.delete-attribute {
					margin: 33px 0px 10px 0px;

					.delete-button {
						margin: 0;
					}
				}
			}

			.add-custom-attributes-button {
				text-align: center;

				.button-icon {
					margin: 0px 6px;
					font-size: 15px;
				}
			}
		}
	}
}

.saved-data-container {
	display: grid;
	grid-template-columns: auto 180px;

	.delete-item-button {
		margin-top: 17px;
	}
}

.chatdataform-alert-block {
	margin-bottom: 0;
}
