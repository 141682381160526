.mediatypes-container {
    .headings-container {
		display: grid;
		grid-template-columns: auto auto;

		h4 {
			margin: 10px 0px;
			&.description {
				margin-left: 30px;
			}
		}
	}
}