.layout-sitewide-banner {
	display: none;
	flex-grow: 0;

	color: black;
	background-color: #fff8eb;
	border-bottom: 1px solid #fbbe3b;
	margin: 0;
	padding: 20px 200px;
	text-align: center;
}
