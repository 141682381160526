@import '../../theme/variables.scss';

.page-content {
	.title-box {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 4px;
		margin: 8px 0 20px 0;

		.page-title {
			margin: 0;
		}
	}

	&.bookmarkable {
		.title-box {
			margin-left: -22px;
		}
	}

	.tag {
		margin-left: 12px;
		font-size: 16px;
		line-height: 18px;
		padding: 2px 12px;
	}
}
