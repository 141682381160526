.sdk-invocation-display {
	.sdk-info {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		.github-link {
			font-size: 24px;
			line-height: 0;
		}
	}

	.sdk-example.fence {
		margin: 10px 0;
	}
}
