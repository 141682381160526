.tabbed-content {
	margin: 20px 0;

	.tab-titles {
		border-bottom: 1px solid #bfd4e4;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;

		.tab-title {
			display: inline-block;
			padding: 5px 20px;
			border-bottom: 1px solid transparent;
			cursor: pointer;

			&.active {
				// border-bottom-color: #bfd4e4;
				border-bottom-color: #597393;
				font-weight: bold;
			}

			// Make markdown-parsed paragraphs look normal
			& p {
				margin: 0;
				display: inline;
			}
		}
	}

	.tab-content {
		padding: 20px 0 0 0;
	}
}
