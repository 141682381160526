.image-uploader {
	display: none;
}

.svg-container {
	img {
		width: 100px;
		height: 100px;
	}
}
