@import '../../../../theme/variables.scss';

.layout-header {
	position: relative;
	flex-grow: 0;
	z-index: 10;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 80px;

	height: 64px;
	flex-shrink: 0;
	margin: 0;
	border-bottom: 1px solid var($--theme-core-layout-border-color);
	background-color: var($--theme-core-background-color);
	box-shadow: 0px 2px 10px var($--theme-core-box-shadow-color);

	.header-image {
		height: 32px;
		margin: -12px 0 0 20px;
		
		&.variant-pride {
			margin: 0px 0 0 20px;
		}
	}

	.account-switcher-header-container {
		.header-account-switcher {
			position: absolute;
			top: 7px;
			right: 35px;
			z-index: 100;
		}
	}
}
