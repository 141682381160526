@import '../../theme/variables.scss';

.dx-link {
	&:link,
	&:active,
	&:visited,
	&:focus {
		color: var($--theme-core-link-color);
		text-decoration: none;
	}

	&:hover {
		text-decoration: none;
		color: var($--theme-core-link-hover-color);
	}

	.dx-link-icon {
		margin-left: 4px;
		font-size: 90%;
	}

	.inline-download {
		margin-left: 3px;
		position: relative;
		top: 4px;
	}

	.external-link {
		margin-left: 3px;
		margin-right: 2px;
		position: relative;
		top: 2px;
	}
}
