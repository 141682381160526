@import '../../../../../theme/variables.scss';

.object-container {
	margin: 6px 6px 6px 12px;
	border-left: 1px solid var($--theme-core-layout-border-color);
	padding-left: 6px;

	&.root-element {
		margin-left: 0;
	}

	.class-name {
		cursor: pointer;
		padding: 0 0 3px 0;
		border-bottom: 1px solid #606060;

		.expand-icon {
			margin-right: 6px;
		}
	}
}

// Strip indentation from objects that are array members
.array-container > .property {
	margin: 0;
	& > .object-container {
		margin-left: 0;
	}
}
