.topic-content {
	margin-top: 12px;

	.transport-permissions-container {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;

		& > div {
			width: 49%;

			h3 {
				margin-top: 6px;
			}
		}
	}

	.parameter-list-container {
		width: 100%;
		display: flex;

		& > div {
			max-width: 50%;
			flex-basis: 50%;

			h4 {
				margin-top: 0;
			}

			.parameter-list {
				list-style-type: none;
				padding: 0 6px;

				.channels-dropdown {
					margin: 0;
				}

				.param-textbox {
					display: inline-block;
					width: 280px;
					padding: 0px 0px 12px 0px;
					margin: 0;
				}
			}
		}
	}

	.request-controls {
		margin: 16px 0px;
		display: flex;
		justify-content: space-between;

		.account-card-container {
			.account-card {
				margin: 0;
			}
		}

		.subscribe-button-container {
			flex-grow: 1;
			align-self: stretch;
			margin: 6px 12px;

			button {
				width: 100%;
				height: 100%;
				margin: 0;
			}
		}
	}

	.response-output {
		margin: 4px 0px;

		.event-bridge-warning {
			margin: 0;
		}
	}

	.button-icon {
		margin-right: 6px;
	}

	.copy-link-button {
		.tooltip-tip.top {
			// Adjust tooltip position
			top: -20px;
			bottom: unset;
		}
	}

	.permission-tooltip {
		cursor: pointer;

		.permission-icon {
			margin-left: 6px;
			font-size: 18px;
			color: #24a357;
			padding: 0;

			&-small {
				font-size: 12px;
			}

			&-warn {
				color: #fbbe3b;
			}

			&-fail {
				color: #cc3336;
			}
		}
	}

	.warning {
		white-space: nowrap;
	}
}
