@import '../../../theme/variables.scss';

$cellWidthPadding: 10px;

.table-container {
	margin: 40px 0;

	.filter-container {
		display: flex;
		flex-flow: row nowrap;
		gap: 8px;
		width: 100%;
		box-sizing: border-box;

		.filter-toggle {
			color: var($--theme-core-link-color);
			font-size: 12px;
			line-height: 0;
			margin: 6px 0 0 10px;
			// padding: 2px;
			cursor: pointer;
			align-self: flex-start;

			&:hover {
				color: var($--theme-core-link-hover-color);
			}
		}
	}

	.table {
		width: 100%;
		margin: 0 30px 0 0;
		color: var($--theme-datatable-text-color);
		background-color: var($--theme-datatable-background-color);

		// Remove paragraph padding from all cells
		td {
			border-width: 0 0 1px 0;
			border-style: solid;
			border-color: var($--theme-datatable-border-color);
			vertical-align: top;
			min-width: 80px;

			& p {
				margin: 0;
				min-width: 10%;
			}
		}

		thead {
			tr {
				color: var($--theme-datatable-text-color);
				font-weight: bold;
				font-size: 14px;
				line-height: 24px;

				td {
					padding: 0 $cellWidthPadding;

					.header-container {
						margin: 0;
						padding: 0;
						display: flex;
						flex-flow: row nowrap;
						justify-content: flex-start;
						align-items: center;
						gap: 6px;
						width: 100%;

						&.align-center {
							justify-content: center;
						}

						&.align-right {
							justify-content: flex-end;
						}
					}

					.sort-numeric {
						display: flex;
						flex-flow: row nowrap;
						gap: 8px;
						align-items: center;

						.dx-label {
							max-width: 70px;
						}
					}

					.sort-date {
						display: flex;
						flex-flow: row nowrap;
						gap: 8px;
						align-items: center;

						.date-filter {
							max-width: 155px;
						}
					}
				}

				&.filter-spacer {
					height: 10px;
				}

				&.filter-row {
					background-color: var($--theme-datatable-filter-background-color);

					td {
						border-width: 1px 0;
						vertical-align: middle;
					}
					td:first-child {
						border-width: 1px 0 1px 1px;
						border-radius: 4px 0 0 4px;
					}
					td:last-child {
						border-width: 1px 1px 1px 0;
						border-radius: 0 4px 4px 0;
					}
				}
			}
		}

		tbody {
			tr {
				font-size: 12px;
				line-height: 20px;

				td {
					padding: 16px $cellWidthPadding;

					& > div {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						justify-content: flex-start;
						gap: 10px;

						&.align-center {
							justify-content: center;
						}

						&.align-right {
							justify-content: flex-end;
						}

						.copy-button {
							color: unset;
						}

						.icon {
							line-height: 0;
						}
					}
				}
			}
		}

		// Reduce outside edge padding
		tr {
			td:first-child {
				padding-left: 12px;
			}
			td:last-child {
				padding-right: 12px;
			}
		}
	}

	&.paginated {
		.table {
			margin-bottom: 10px;
		}
	}

	&.sortable {
		.table {
			thead tr {
				td {
					cursor: pointer;

					.sort-icon,
					.filter-active-icon {
						color: var($--theme-core-link-color);
					}

					&.unsorted .sort-icon {
						visibility: hidden;
					}

					&:hover .sort-icon {
						visibility: visible;
					}
				}
			}
		}
	}
}
