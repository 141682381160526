@import '../../../../theme/variables.scss';

.mediatype-tile {
    margin: 6px 3px;
    border-left: 8px solid transparent;
    
    .tile-container {
		border-width: 1px 1px 1px 0;
		border-style: solid;
		border-color: var($--theme-core-layout-border-color);
		border-radius: 0 4px 4px 0;
        padding: 10px 9px;
        display: flex;
    }

    .tile-text {
        line-height: 22px;
        flex: 1 0;
        min-width: 50%;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .media-type {
        font-weight: bold;
    }

    .sub-types {
        font-weight: bold;
    }

    ul {
      list-style-type: circle;
      margin: 0;
      padding: 0;
    }

    &.subtypes-available {
		border-left-color: #c78800;
	}

	&.subtypes-na {
		border-left-color: #8452cf;
	}
}