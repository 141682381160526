.channel-content-container {
	height: 100px;
	display: flex;
	flex-direction: column;

	.channel-content-heading {
		font-size: 32px;
		line-height: 37px;
		font-weight: 300;
		margin: 0;
	}

	.channel-content-link {
		margin-bottom: 15px;
	}

	.channel-info-container {
		margin: 15px 0px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;

		.channel-info {
			white-space: nowrap;
			font-size: 15px;
			overflow: hidden;
			text-overflow: ellipsis;

			.channel-info-label {
				font-weight: bold;
			}

			.channel-info-value {
				margin-left: 7px;
			}
		}
	}

	.filter-subscription-container {
		display: flex;
		margin: inherit;
		margin-bottom: 15px;
		padding: auto;

		.filtering-container {
			height: 165px;

			.subscribed-topics {
				max-height: 100px;
				overflow: scroll;

				.filter-topics-checkboxes {
					white-space: nowrap;
					margin: 0;
				}
			}

			.chevron {
				font-size: 18px;
				cursor: pointer;
				margin: auto;
			}

			.-hidden {
				display: none;
			}

			.topics-heading-container {
				margin: 7px 0px 3px 0px;

				.topics-heading {
					margin-top: 0;
				}
			}
		}

		.section-heading {
			line-height: 25px;
			font-size: 20px;
			cursor: pointer;
			margin-right: 1px;
			margin-top: 5px;
		}

		& > div {
			width: 50%;
		}

		.subscription-container {
			margin: 0px;
			margin-left: 5px;
			max-height: 150px;

			.parametereditor-container {
				max-height: 115px;
				margin-top: 7px;
			}
		}
	}

	.eventList {
		margin: 10px 0px;
		max-height: 100%;
		overflow: scroll;
	}
}
